import React, {Fragment, useEffect} from 'react';
import {levelSlots} from "./slotUtils";
import {IncrementDecrementor} from "./IncrementDecrementor";
import {Orb} from "./Orb";


export const Slots = ({level, usedSlots, setUsedSlots}) => {

    useEffect(() => localStorage.setItem('usedSlots', JSON.stringify(usedSlots)), [usedSlots])

    const availableSlots = levelSlots[level];
    const updateUsed = (i, change, used, total) => {
        const newUsed = used + change;
        if (newUsed < 0 || newUsed > total) return;
        setUsedSlots(oldVal => {
            let newVal = [...oldVal];
            newVal[i] = newUsed
            return newVal;
        })
    }

    const renderSlots = (totalSlots, i) => {
        const used = usedSlots[i] ?? 0;
        const slots = [];
        for (let j = 0; j < totalSlots; j++) {
            slots.push(
                <div
                    key={`slot-${i}-${j}`}
                    className="slot"
                >
                    <Orb key={`orb${i}`} spent={used > j} level={level}/>
                </div>
            )
        }

        slots.reverse()

        return (
            <Fragment
                key={`levelSlots${i}`}
            >
                <IncrementDecrementor
                    className="levelSlots"
                    onChange={change => updateUsed(i, change, used, totalSlots)}
                >
                    <div className="label">
                        <span>
                            {i + 1}:
                        </span>
                    </div>
                    {slots}
                </IncrementDecrementor>
            </Fragment>
        )
    }

    return (
        <div className="slots">
            <h3>Slots</h3>
            {availableSlots.map(renderSlots)}
        </div>
    );
}