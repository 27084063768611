export const sortSpells = (a, b) =>
    levelSort(a, b, (a, b) => lightSort(a, b, nameSort));




const levelSort = (a, b, fallback) => a.level > b.level ? 1 : a.level < b.level ? -1 : fallback ? fallback(a, b) : 0;
const nameSort = (a, b, fallback) => a.name > b.name ? 1 : a.name < b.name ? -1 : fallback ? fallback(a, b) : 0;
const lightSort = (a, b, fallback) =>
    a.light && !b.light ? -1 :
    !a.light && b.light ? 1 :
        0;

export const stringMatch = (spellName, searchTerm) => {
    let name = spellName.toLowerCase(), term = searchTerm.toLowerCase();
    if (name.toLowerCase().includes(term.toLowerCase()))
        return true;

    let splitName = name.split(' '),
        splitTerm = term.split(' ');

    let nameStringIndex = 0;
    for (let i = 0; i < splitTerm.length; i++) {
        const termPart = splitTerm[i];
        while (splitName[nameStringIndex]) {
            const namePart = splitName[nameStringIndex];
            let foundIt = (namePart.startsWith(termPart) || namePart.endsWith(termPart));
            nameStringIndex++;
            if (foundIt) {
                if (i === splitTerm.length - 1)
                    return true;
                break;
            }
        }
    }
}

export const addDividers = spells => {
    let prev;
    return spells.reduce((acc, s) => {
        let thing = [...acc];
        if (prev && prev.level !== s.level)
            thing.push({
                divider: true,
                level: s.level,
                name: `sep-${s.name}`
            })
        thing.push(s);
        prev = s;
        return thing;
    }, [])
}
