import React from 'react';
import {Spell} from "../../Components/Spell";
import './Spells.css'
import { ReactComponent as Skull } from "../../resources/skull.svg";

import {addDividers, sortSpells} from "../../utils/utils";
import {RemoveButton} from "../../Components/RemoveButton";

export const Spells = ({mySpells, spellBookRef, setMySpells}) => {

    const remove = (name) => () => {
        setMySpells(prev => prev.filter(s => s.name !== name));
    }

    return (
        <div
            className="spells page"
            ref={spellBookRef}
        >
            <div className="spellsHeader">
                <h2>Spell book</h2>
            </div>
            <div className="spellsContent">
                {
                    mySpells && mySpells.length > 0 ?
                        addDividers(mySpells.sort(sortSpells)).map(s => (
                            <Spell
                                key={s.name}
                                spell={s}
                                remove={remove(s.name)}
                                added
                                removeButton={
                                    <RemoveButton
                                        spell={s}
                                        setMySpells={setMySpells}
                                    />
                                }
                            />
                        ))
                        : <Skull/>

                }
            </div>
        </div>
    );
}
