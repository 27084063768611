import React from 'react';
import { ReactComponent as Star } from "../resources/star.svg";

export const FavouriteButton = ({active, onClick}) => (
    <button
        className={`favouriteButton ${active ? 'active' : ''}`}
        onClick={onClick}
    >
        <Star/>
    </button>
);