import React, {useEffect, useState} from 'react';
import './SlotsAndPoints.css'
import {Level} from "./Level";
import {Slots} from "./Slots";
import {Points} from "./Points";
import {getStored} from "../../App";
import { ReactComponent as Rest } from "../../resources/rest.svg";

export const SlotsAndPoints = ({level, setLevel, lightCleric}) => {
    const [usedSlots, setUsedSlots] = useState(getStored('usedSlots', []))
    const [usedPoints, setUsedPoints] = useState(getStored('usedPoints', 0))

    useEffect(() => localStorage.setItem('slotsLevel', level), [level])
    useEffect(() => localStorage.setItem('usedPoints', usedPoints), [usedPoints])

    const rest = () => {
        setUsedSlots([]);
        setUsedPoints(0);
    }

    return (
        <div className="slotsAndPoints page noSelect">
            <button
                className="restButton"
                onClick={rest}
            >
                <Rest/>
            </button>
            <h2><Level
                level={level}
                setLevel={setLevel}
            /></h2>
            <div className="spacer"/>
            <Slots
                level={level}
                usedSlots={usedSlots}
                setUsedSlots={setUsedSlots}
            />
            {lightCleric || <Points
                key={`pointsForLvl${level}`}
                level={level}
                usedPoints={usedPoints}
                setUsedPoints={setUsedPoints}
            />}
            <div className="spacer"/>
            <div className="spacer"/>
            <div className="spacer"/>
        </div>
    );
}