import React, {useEffect, useMemo, useState} from 'react';
import './App.css';
import {Spells} from "./Pages/Spells/Spells";
import {Search} from "./Pages/Search/Search";
import {PrivacyPolicy} from "./Pages/PrivacyPolicy";
import {WildMagic} from "./Pages/WildMagic/WildMagic";
import useEffectPartialDeps from "./utils/UseEffectPartialDeps";
import {SlotsAndPoints} from "./Pages/SlotsAndPoints/SlotsAndPoints";
import {getLightClericSpells} from "./Spells";

export const getStored = (key, defaultValue) => {
    const stored = localStorage.getItem(key);
    if (!stored) return defaultValue;
    return JSON.parse(stored);
}

const getMyLocalSpells = () => {
    let s = localStorage.getItem('my-spells')
    return s ? JSON.parse(s) : [];
}

let initScrollDone, spellBookRef = React.createRef();

function App() {
    const [width, setWidth] = useState();
    const [showWildMagic, setShowWildMagic] = useState(localStorage.getItem('showWildMagic') === 'true');
    const [showSlots, setShowSlots] = useState(localStorage.getItem('showSlots') === 'true');
    const [lightCleric, setLightCleric] = useState(localStorage.getItem('lightCleric') === 'true');
    const [level, setLevel] = useState(localStorage.getItem('slotsLevel') ?? 1);
    const [mySpells, setMySpells] = useState(getMyLocalSpells());
    const [lightSpells] = useMemo(() => lightCleric ? [getLightClericSpells(level)] : [], [level, lightCleric])

    useEffect(() => localStorage.setItem('my-spells', JSON.stringify(mySpells)), [mySpells])

    useEffect(() => {
        const extraPagesCount = [showSlots, showWildMagic].filter(t => !!t).length;
        setWidth(() => window.innerWidth * (2 + extraPagesCount));
        localStorage.setItem('showWildMagic', JSON.stringify(showWildMagic));
        localStorage.setItem('showSlots', JSON.stringify(showSlots));
        localStorage.setItem('lightCleric', JSON.stringify(lightCleric));
    }, [showWildMagic, showSlots, lightCleric])

    // useEffectPartialDeps(() => {
    //     if (mySpells && mySpells.length === 0) {
    //         initScrollDone = true;
    //         return;
    //     }
    //     if (initScrollDone || !spellBookRef.current) return;
    //     spellBookRef.current.scrollIntoView({behavior: "smooth"});
    //     initScrollDone = true;
    // }, [width, mySpells])

    if (window.location.pathname === "/privacy")
        return (
            <div className={'App'}>
                <PrivacyPolicy/>
            </div>
        )
    if (!width) return <></>

    return (
        <div className={'App'}>
            <div
                className="contentWrapper"
                style={{width}}
            >
                <Search
                    mySpells={mySpells}
                    lightCleric={lightCleric}
                    setMySpells={setMySpells}
                    flipWildMagic={() => setShowWildMagic(wm => !wm)}
                    flipShowSlots={() => setShowSlots(wm => !wm)}
                    flipLightCleric={() => setLightCleric(wm => !wm)}
                />
                <Spells
                    mySpells={[...mySpells, ...(lightSpells ?? [])]}
                    setMySpells={setMySpells}
                    spellBookRef={spellBookRef}
                    lightCleric={lightCleric}
                />
                {showSlots && (<SlotsAndPoints level={level} setLevel={setLevel} lightCleric={lightCleric}/>)}
                {showWildMagic && (<WildMagic/>)}
            </div>
        </div>
    );
}

export default App;
