import React, {useState} from 'react';
import './Picker.css'

export const Picker = ({
                           selectedLevels,
                           setSelectedLevels,
                           selectedClass,
                           setSelectedClass,
                           onLevelClick
}) => {
    const [open, setOpen] = useState(null);
    const levelOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(n => ({label: `${n}`, value: n}))
    const classOptions = ["Bard", "Cleric", "Druid", "Paladin", "Ranger", "Sorcerer", "Warlock", "Wizard"].map(v => ({value: v}))


    const renderContent = () => {
        if (!open) return <></>;
        let options = open === 'levels' ? levelOptions : classOptions;
        return (
            <div className="pickerContent">
                <div className="options">
                    {options.map(open === 'levels' ? renderLevelOption : renderClassOption)}
                    {open === 'levels' && <div className={'option button'} onClick={() => selectedLevels([])}>none</div>}
                    {open === 'levels' && <div className={'option button'} onClick={() => selectedLevels(options.map(o => o.value))}>all</div>}
                </div>
            </div>
        )
    }


    const renderClassOption = (o) => {
        const active =  selectedClass === o.value;
        return (
            <div
                key={o.value}
                className={`option ${active ? 'active' : ''}`}
                onClick={() => active ? setSelectedClass() : setSelectedClass(o.value)}
            >
                {o.label || o.value}
            </div>
        )
    }

    const renderLevelOption = (o) => {
        const active = selectedLevels.includes(o.value);
        return (
            <div
                key={o.value}
                className={`single option ${active ? 'active' : ''}`}
                onClick={() => {
                    onLevelClick(o.value)
                    if (active) {
                        let newSelected = [...selectedLevels];
                        newSelected.splice(selectedLevels.findIndex(s => s === o.value), 1);
                        setSelectedLevels(newSelected);
                    } else
                        setSelectedLevels([
                            ...selectedLevels,
                            o.value
                        ])
                }
                }
            >
                {o.label || o.value}
            </div>
        )
    }

    let levelHeadingThing, classHeadingThing;

    if (open !== 'levels' && selectedLevels && selectedLevels.length > 0)
        levelHeadingThing = selectedLevels.join(', ')
    if (open !== 'classes' && selectedClass)
        classHeadingThing = `: ${selectedClass}`

    return (
        <div className="picker">
            <div className="pickerTabs">
                <div
                    className={`tabHeader ${levelHeadingThing} ${open === 'levels' ? 'active' : ''}`}
                    onClick={() => setOpen(po => po === 'levels' ? null : 'levels')}
                >Levels {levelHeadingThing}</div>
                <div
                    className={`tabHeader ${classHeadingThing} ${open === 'classes' ? 'active' : ''}`}
                    onClick={() => setOpen(po => po === 'classes' ? null : 'classes')}
                >Class{classHeadingThing}</div>
            </div>
            {renderContent()}
        </div>
    );
}