import React, {useState} from 'react';
import './Spell.css'
import {FavouriteButton} from "./FavouriteButton";

export const Spell = ({
                          spell,
                          added,
                          addButton,
                          removeButton,
                          adding,
                          isFavourite,
                          toggleFavourite
}) => {
    const [show, setShow] = useState(false);

    if (spell.divider) return <div className="spellDivider">
        <div/>
        <span>{spell.level}</span>
        <div/>
    </div>;

    const renderDamage = (prefix) => {
        if (!spell.damage) return <></>

        const amount =
            spell.damage.damage_at_slot_level ? Object.values(spell.damage.damage_at_slot_level)[0] :
                spell.damage.damage_at_character_level ? Object.values(spell.damage.damage_at_character_level)[0] :
                    '';
        const type = spell.damage.damage_type ? spell.damage.damage_type.name : '';

        let desc;

        if (amount && type) desc = <>{amount} <span className="detail">({type})</span></>
        else if (amount) desc = amount
        else if (type) desc = type
        else return <></>
        return <p>{prefix ? <span className="detail">{prefix}</span> : ''}{desc}</p>
    }

    const optionalRender = (prefix, value, wrapValue) =>
        value ? (
            <p>{prefix ? <span className="detail">{prefix}</span> : ''}{
                wrapValue ? (<span className="wrappable">{value}</span>) : value
            }</p>
        ) : <></>;

    const renderDetails = () => show ? (
        <div className="spellDetails">
            {/*{!spell.light && !addButton ? (<RemoveButton action={remove}/>) : ''}*/}
            <div className="topDetails">
                <div className="topDetailsBlock">
                    {renderDamage('Damage: ')}
                    {optionalRender('Range: ', spell.range)}
                    {optionalRender('Casting time: ', spell.casting_time)}
                    {optionalRender('Concentration: ', `${spell.concentration}`)}
                </div>
                <div className="topDetailsBlock">
                    {optionalRender('Components: ', spell.components && spell.components.join(', '))}
                    {optionalRender('Duration: ', spell.duration)}
                    {optionalRender('School: ', spell.school)}
                    {optionalRender('Classes: ', spell.classes && spell.classes.replaceAll(',', ', '), true)}
                </div>
            </div>
            <div className="spellDesc">
                <p>{spell.description}</p>
                {spell.cast_higher && (<p>{spell.cast_higher}</p>)}
            </div>
        </div>
    ) : (<></>)

    const renderSpellShadow = (go) => {
        return adding &&(
            <div className={`spell shadow ${adding.gone ? 'gone' : ''} ${go ? 'go' : 'fadeIn'}`}>
                <div
                    className="spellHeader"
                >
                    {
                        addButton ? (
                            <FavouriteButton
                                active={isFavourite}
                                onClick={() => toggleFavourite(spell)}
                            />
                        ) : ''
                    }
                    <div className="spellHeaderText">
                    <span className="spellName">
                        {spell.name}
                    </span>
                        <span className="spellLvl">
                        lvl{' '}{spell.level}
                    </span>
                    </div>
                    {go ? addButton : removeButton}
                </div>
            </div>
        )
    }

    return (
        <>
            <div className={`spell ${spell.light ? 'light' : ''} ${adding ? 'adding' : ''} ${adding && adding.gone ? 'gone' : ''}`}>
                <div
                    className={`spellHeader`}
                >
                    {
                        addButton ? (
                            <FavouriteButton
                                active={isFavourite}
                                onClick={() => toggleFavourite(spell)}
                            />
                        ) : ''
                    }
                    <div
                        className="spellHeaderText"
                        onClick={() => setShow(s => !s)}
                    >
                    <span className="spellName">
                        {spell.name}
                    </span>
                        <span className="spellLvl">
                        lvl{' '}{spell.level}
                    </span>
                    </div>
                    {spell.light ? undefined : added ? removeButton : addButton}
                </div>
                {renderDetails()}
            </div>
            {renderSpellShadow(true)}
            {renderSpellShadow()}
        </>
    );
}