import React from 'react';
import { ReactComponent as Minus } from "../resources/minus.svg";

export const RemoveButton = ({spell, setMySpells}) => (
        <button
            className={'addOrRemoveSpellButton removeButton'}
            onClick={() => {
                setMySpells(prev => prev.filter(s => spell.name !== s.name));
            }}
        >
            <Minus/>
        </button>
    );