import React, {useEffect, useState} from 'react';
import './Search.css'
import {Picker} from "../../Picker/Picker";
import {Spell} from "../../Components/Spell";
import {addDividers, sortSpells, stringMatch} from "../../utils/utils";
import { ReactComponent as Plus } from "../../resources/plus.svg";
import { ReactComponent as Cross } from "../../resources/cross.svg";
import {markLightSpells, spells} from '../../Spells'
import useEffectOnce from "../../utils/UseEffectOnce";
import {RemoveButton} from "../../Components/RemoveButton";
import {FavouriteButton} from "../../Components/FavouriteButton";

const getLocal = (key, string) => {
    let s = localStorage.getItem(key);
    return s ? string ? s : JSON.parse(s) : null;
}

const getFavouriteSpells = () => {
    let s = localStorage.getItem('favourites')
    return s ? JSON.parse(s) : [];
}

let code = [],
    wildKey = [1, 3, 3, 7],
    slotsKey = [8, 8, 8, 8],
    lightKey = [6, 6, 6, 6];

export const Search = ({mySpells, setMySpells, flipWildMagic, flipShowSlots, flipLightCleric, lightCleric}) => {
    const [searchFocus, setSearchFocus] = useState(false)
    const [favFilter, setFavFilter] = useState(getLocal('levels') || false)
    const [search, setSearch] = useState('')
    const [levels, setLevels] = useState(getLocal('levels') || []);
    const [favourites, setFavourites] = useState(getFavouriteSpells());
    const [playerClass, setPlayerClass] = useState(getLocal('class', true));

    const [adding, setAdding] = useState({});

    const addFavourite = (s) => {
        setFavourites(prev => {
            return [...prev, s.name]
        });
    }
    const removeFavourite = (s) => {
        setFavourites(prev => {
            return prev.filter(sn => sn !== s.name)
        });
    }

    useEffectOnce(() => {
        localStorage.removeItem('spells');
        localStorage.removeItem('mySpells');
    });

    const clearCode = () => {
        code = [];
    }

    const onLevelClick = (l) => {
        if (code.length === 4) code.shift();
        code.push(l);
        if (JSON.stringify(wildKey) === JSON.stringify(code)) {
            clearCode();
            flipWildMagic();
        }
        if (JSON.stringify(slotsKey) === JSON.stringify(code)) {
            clearCode();
            flipShowSlots();
        }
        if (JSON.stringify(lightKey) === JSON.stringify(code)) {
            clearCode();
            flipLightCleric();
        }
    }

    const save = (key, value, stringify) =>
        localStorage
            .setItem(
                key,
                stringify ? JSON.stringify(value) : value || ''
            )


    useEffect(() => save('class', playerClass), [playerClass])
    useEffect(() => {save('favourites', favourites, true)}, [favourites])
    useEffect(() => {save('favFilter', favFilter, true)}, [favFilter])
    useEffect(() => save('levels', levels, true), [levels])

    if (!spells) return <></>;

    const renderResults = () => {
        if (!spells) return <><p>This takes time</p><p>Once</p></>
        // let max = undefined;
        // spells.forEach(s => {
        //     const length = s.name.length;
        //     if (!max || length > max.length) {
        //         max = {
        //             length,
        //             s
        //         }
        //     }
        // })
        // console.log('max: ', max);


        let filtered = getFilteredResults();
        if (lightCleric)
            filtered = filtered.filter(s => !s.light);
        if (filtered.length === 0) return <p>No match</p>;
        if (lightCleric)
            filtered = markLightSpells(filtered);
        return addDividers(
            filtered.sort(sortSpells)
        ).map(renderResult);
    }


    const mySpellNames = mySpells.map(s => s.name);
    const renderResult = (s) => {
        const isFavourite = !favourites || favourites.includes(s.name);
        return (
            <div key={s.name} className="spellResultWrapper">
                <Spell
                    spell={s}
                    addButton={renderAddButton(s)}
                    removeButton={renderRemoveButton(s)}
                    added={mySpellNames.includes(s.name)}
                    adding={adding[s.name]}
                    lightCleric={lightCleric}
                    isFavourite={isFavourite}
                    toggleFavourite={isFavourite ? removeFavourite : addFavourite}
                />
            </div>
        )
    }

    const renderRemoveButton = (s) => {
        return (
            <RemoveButton
                spell={s}
                setMySpells={setMySpells}
            />
        )
    }

    const renderAddButton = (s) => {
        return (
            <button
                className={`addOrRemoveSpellButton addButton`}
                onClick={() => {
                    setMySpells([...mySpells, s])
                    setAdding(a => ({...a, [s.name]: {gone: false} }));
                    setTimeout(() => setAdding(a => ({...a, [s.name]: {gone: true} })))
                    setTimeout(() => setAdding(a => ({...a, [s.name]: undefined})), 500)
                }}
            >
                <Plus/>
            </button>
        )
    }


    const getFilteredResults = () => {
        let filtered = [...spells];

        if (levels.length > 0)
            filtered = filtered.filter(s => levels.includes(s.level))

        if (playerClass)
            filtered = filtered.filter(s => s.classes.split(',').some(c => c === playerClass))

        if (search)
            filtered = filtered.filter(s => stringMatch(s.name, search))

        if (favFilter && favourites)
            filtered = filtered.filter(s => favourites.includes(s.name))

        return filtered;
    }

    return (
        <div className="search page">
            <div className="filters">
                <h2>Archive</h2>
                <div className="searchBox">
                    <input
                        autoComplete={"off"}
                        id="nameSearch"
                        className="input"
                        type="search"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        onFocus={() => setSearchFocus(true)}
                        onBlur={() => setSearchFocus(false)}
                    />
                    <button
                        className="clearSearchBoxButton"
                        onClick={() => setSearch('')}
                    >
                        <Cross/>
                    </button>
                </div>
                <div className={`levelAndClass ${searchFocus ? 'hidden' : ''}`}>
                    <FavouriteButton
                        active={favFilter}
                        onClick={() => {setFavFilter(prev => !prev)}}
                    />
                    <Picker
                        selectedLevels={levels}
                        setSelectedLevels={setLevels}
                        selectedClass={playerClass}
                        setSelectedClass={setPlayerClass}
                        onLevelClick={onLevelClick}
                    />
                </div>
                <div className="filterFade">
                    <p>
                        Spells:
                    </p>
                </div>
            </div>
            <div className="results">
                {renderResults()}
            </div>
        </div>
    );
}